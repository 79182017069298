import axios, { adminApiInstance } from './axios'
import * as paths from '../services/Paths'
import { AxiosResponse } from 'axios'
import { parseAxiosErrorResponse } from '../utility'
import { Location } from '../models/Location'

class LocationService {
  fetchLocations(token: string): Promise<Location[]> {
    return adminApiInstance
      .get(paths.ADMIN_LOCATIONS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  //ADMIN REQUESTS

  fetchAdminLocations(token: string, page: number, search?: string) {
    const path = search
      ? `${paths.ADMIN_LOCATIONS}/search?page=${page}&spotName=${search}`
      : `${paths.ADMIN_LOCATIONS}/search?page=${page}`
    return adminApiInstance
      .get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => {
        return {
          locations: data.data,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  saveLocation(token: string, locationData: Location) {
    return axios
      .post(paths.GET_LOCATIONS, locationData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  editLocation(token: string, locationData: Location) {
    return adminApiInstance
      .put(paths.updateLocationById(locationData.id), locationData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  deleteLocation(locationId: string, token: string) {
    return adminApiInstance
      .delete(paths.deleteLocationById(locationId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  mergeLocation(locationId: string, newLocationId: string, token: string) {
    return adminApiInstance
      .delete(`${paths.ADMIN_LOCATIONS}/${newLocationId}/${locationId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  getAlbumsCountByLocation(locationId: string, token: string) {
    return adminApiInstance
      .get(paths.albumsCountByLocation(locationId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }
}

export default LocationService
