import React from 'react'
import UploadCoverPhotograph from './UploadCoverPhotograph'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import { ModalButtonsDisplay, ModalSize } from '../Modal/Modal'
import { Event } from 'models/Event'

type UploadPhotographModalProps = {
  handleClose: (event?: Event) => void
  open: boolean
  event: Event
  isEventCover?: boolean
  isEventBanner?: boolean
}

const UploadPhotographModal = ({
  handleClose,
  open,
  event,
  isEventCover = false,
  isEventBanner = false,
}: UploadPhotographModalProps) => {
  const { t } = useTranslation()
  const title = isEventCover ? t('Add cover photograph') : isEventBanner ? t('Add banner') : ''
  return (
    <Modal
      okMessage={t('Save')}
      onCancel={() => handleClose(event)}
      onSuccess={() => handleClose(event)}
      opened={open}
      buttonsDisplay={ModalButtonsDisplay.ONLY_OK_BUTTON}
      modalSize={ModalSize.SMALL}
    >
      <div className="flex flex-col">
        <h1 className="font-bold text-lg">{title}</h1>
        <UploadCoverPhotograph
          event={event}
          isEventCover={isEventCover}
          isEventBanner={isEventBanner}
        />
      </div>
    </Modal>
  )
}

export default UploadPhotographModal
