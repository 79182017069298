import { makeAutoObservable, runInAction } from 'mobx'
import AuthService from '../services/AuthService'
import { User } from '../models/User'
import { isNil } from 'lodash'

const LocalStorageKeys = {
  Token: 'token',
  AdminToken: 'adminToken',
  ExpirationDate: 'expirationDate',
  LoggedUser: 'loggedUser',
}

class AuthStore {
  private token: string | null
  private adminToken: string | null
  public error: string | null
  private loggedUser: User | null
  public isLoading: boolean
  constructor(private readonly authService: AuthService) {
    this.token = null
    this.adminToken = null
    this.error = null
    this.loggedUser = null
    this.isLoading = false
    makeAutoObservable(this)
    this.checkAuthState()
  }

  getToken(): string {
    if (this.isAuthenticated()) {
      return this.token!
    } else {
      throw new Error('Debes iniciar sesión.')
    }
  }

  getAdminToken(): string {
    if (this.isAuthenticated()) {
      return this.adminToken!
    } else {
      throw new Error('Debes iniciar sesión.')
    }
  }

  getLoggedUser(): User {
    if (!isNil(this.loggedUser)) {
      return this.loggedUser!
    } else {
      throw new Error('you must login to perform this action')
    }
  }

  logout() {
    this.isLoading = false
    this.loggedUser = null
    this.token = null
    this.adminToken = null
    localStorage.removeItem(LocalStorageKeys.Token)
    localStorage.removeItem(LocalStorageKeys.AdminToken)
    localStorage.removeItem(LocalStorageKeys.ExpirationDate)
    localStorage.removeItem(LocalStorageKeys.LoggedUser)
  }

  isAuthenticated() {
    const expirationDateString = localStorage.getItem(LocalStorageKeys.ExpirationDate)
    const expirationDate = !expirationDateString ? new Date() : new Date(expirationDateString)
    if (expirationDate <= new Date()) {
      this.logout()
    }
    return this.token != null
  }

  checkAuthState() {
    const token = localStorage.getItem(LocalStorageKeys.Token)
    const adminToken = localStorage.getItem(LocalStorageKeys.AdminToken)
    const loggedUserString = localStorage.getItem(LocalStorageKeys.LoggedUser)
    const expirationDateString = localStorage.getItem(LocalStorageKeys.ExpirationDate)
    if (isNil(token) || isNil(loggedUserString) || isNil(expirationDateString)) {
      this.logout()
    } else {
      const loggedUser = User.init(JSON.parse(loggedUserString!))
      const expirationDate = new Date(expirationDateString!)
      if (expirationDate <= new Date()) {
        this.logout()
        // toast.warning('Su sesión ha expirado. Por favor ingrese de nuevo.', {
        //   position: 'top-right',
        //   autoClose: 4000,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   hideProgressBar: true,
        // })
      } else {
        runInAction(() => {
          this.token = token
          this.adminToken = adminToken
          this.loggedUser = loggedUser
        })
      }
    }
  }

  startLoading() {
    this.isLoading = true
  }

  stopLoading() {
    this.isLoading = false
  }

  //ASYNC METHODS

  async login(email: string, password: string) {
    try {
      this.startLoading()
      const adminLoginPayload = await this.authService.adminLogin(email.toLowerCase(), password)

      const { accessToken, expiresIn, loggedUser } = adminLoginPayload

      const expirationDate = new Date(new Date().getTime() + Number(expiresIn) * 36000)
      localStorage.setItem('adminToken', accessToken)
      localStorage.setItem('token', accessToken)
      localStorage.setItem('expirationDate', expirationDate.toString())
      localStorage.setItem('loggedUser', JSON.stringify(loggedUser))
      runInAction(() => {
        this.loggedUser = loggedUser
        this.adminToken = accessToken
        this.token = accessToken
        this.stopLoading()
      })
    } catch (e: any) {
      runInAction(() => {
        this.logout()
        this.stopLoading()
      })
      throw e
    }
  }
}

export default AuthStore
