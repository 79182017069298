import React from 'react'
import { faFileArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FileInput from 'components/UI/FileInput'
import { FileTypes } from 'components/UI/FileInput/FileInput'
import { useTranslation } from 'react-i18next'
import Dropzone from 'components/UI/Dropzone'

const validImageTypes = [FileTypes.PNG, FileTypes.JPG]

type ImageUploaderProps = {
  onUpload: (file: File) => void
}

export const ImageUploader = ({ onUpload }: ImageUploaderProps) => {
  const { t } = useTranslation()

  const handleFileInput = (file: File) => {
    onUpload(file)
  }

  const onFileDrop = React.useCallback((file: File) => {
    handleFileInput(file)
  }, [])

  return (
    <>
      <div id="image-uploader-container" className="h-4/5 flex flex-col max-h-full p-0 mb-2">
        <div className="w-full h-full border-dashed border-2 border-spotted-light_black flex flex-col border-lumepic-grey rounded-lg shadow-md">
          <Dropzone onFileDrop={onFileDrop}>
            <div className="flex flex-col items-center justify-center h-full gap-2.5 px-4 py-14">
              <FontAwesomeIcon
                icon={faFileArrowUp}
                className="text-3xl mb-2 text-lumepic-light_black"
              />
              <div className="mb-0 text-center">{t('Drop your file here')}</div>
              <div className="text-lumepic-grey">{'- ' + t('or') + ' -'}</div>
              <FileInput
                handleFile={handleFileInput}
                label={t('Select picture').toString()}
                acceptedFileTypes={validImageTypes}
              />
            </div>
          </Dropzone>
        </div>
      </div>
    </>
  )
}
