import axios, { adminApiInstance } from './axios'
import * as paths from '../services/Paths'
import { parseAxiosErrorResponse } from '../utility'
import UploadCoverPhotographResponse from './Interfaces/CoverPhotograph/UploadCoverPhotographResponse'
import UploadCoverPhotographRequest from './Interfaces/CoverPhotograph/UploadCoverPhotographRequest'
import { AxiosResponse } from 'axios'

export enum UploadedPhotographsAssets {
  ALBUM_COVER = 'ALBUM_COVER',
  EVENT_COVER = 'EVENT_COVER',
  EVENT_BANNER = 'EVENT_BANNER',
}

class PhotographService {
  deletePhotograph(photoId: string, token: string) {
    return axios
      .delete(paths.deletePhoto(photoId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  updatePhotographPrice(photoId: string, newPrice: number, token: string) {
    return axios
      .put(
        `${paths.PHOTOGRAPH}/${photoId}`,
        {
          price: Number(newPrice),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response)
      .catch(parseAxiosErrorResponse)
  }

  getAdminPhotographDownload(token: string, photographId: string) {
    return adminApiInstance
      .get(paths.adminPhotographDownloadUrl(photographId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const link = document.createElement('a')
        link.href = response.data

        link.click()
      })
      .catch(parseAxiosErrorResponse)
  }

  getAdminPhotographDownloadUrl(token: string, photographId: string) {
    return adminApiInstance
      .get(paths.adminPhotographDownloadUrl(photographId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  async createCoverPhotograph(
    assetType: UploadedPhotographsAssets,
    id: string,
    fileType: string,
    token: string
  ): Promise<UploadCoverPhotographResponse> {
    const photographPayload: UploadCoverPhotographRequest = {
      type: fileType,
    }
    const pathMap = {
      [UploadedPhotographsAssets.ALBUM_COVER]: paths.createAlbumCoverPhotograph,
      [UploadedPhotographsAssets.EVENT_COVER]: paths.createEventCoverPhotograph,
      [UploadedPhotographsAssets.EVENT_BANNER]: paths.createEventBannerPhotograph,
    }

    const path = pathMap[assetType]?.(id) || ''

    return adminApiInstance
      .post(path, photographPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchAdminAlbumsPhotographs(albumId: string, token: string, page: number) {
    return axios
      .get(`${paths.photographsFromAlbumById(albumId)}?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return {
          photographs: data.data,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  suspendPhotograph(token: string, photographId: string) {
    return axios
      .post(paths.suspendPhotographById(photographId), photographId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  activatePhotograph(token: string, photographId: string) {
    return axios
      .post(paths.activatePhotographById(photographId), photographId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }
}

export default PhotographService
