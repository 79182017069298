import React from 'react'
import c from 'classnames'
import { Link } from 'react-router-dom'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

const range = (start: number, end: number) => {
  const length = end - start + 1
  return Array.from({ length }, (_, i) => start + i)
}

type PagerProps = {
  totalPages?: number
  currentPage?: number
  boundaryCount?: number
  siblingCount?: number
  startEllipsis?: string
  endEllipsis?: string
  query: URLSearchParams
}

const Pager = ({
  totalPages = 1,
  currentPage = 1,
  boundaryCount = 1,
  siblingCount = 1,
  startEllipsis = '...',
  endEllipsis = '...',
  query,
}: PagerProps) => {
  const startPages = range(1, Math.min(boundaryCount, totalPages))
  const endPages = range(Math.max(totalPages - boundaryCount + 1, boundaryCount + 1), totalPages)

  const getUrlForPage = (page: number | string) => {
    query.set('page', page.toString())

    return `?${query.toString()}`
  }

  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      currentPage - siblingCount,
      // Lower boundary when page is high
      totalPages - boundaryCount - siblingCount * 2 - 1
    ),
    // Greater than startPages
    boundaryCount + 2
  )

  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      currentPage + siblingCount,
      // Upper boundary when page is low
      boundaryCount + siblingCount * 2 + 2
    ),
    // Less than endPages
    endPages.length > 0 ? endPages[0] - 2 : totalPages - 1
  )

  const itemList = [
    ...startPages,

    // Start ellipsis
    ...(siblingsStart > boundaryCount + 2
      ? [startEllipsis]
      : boundaryCount + 1 < totalPages - boundaryCount
      ? [boundaryCount + 1]
      : []),

    // Sibling pages
    ...range(siblingsStart, siblingsEnd),

    // End ellipsis
    ...(siblingsEnd < totalPages - boundaryCount - 1
      ? [endEllipsis]
      : totalPages - boundaryCount > boundaryCount
      ? [totalPages - boundaryCount]
      : []),

    ...endPages,
  ]

  return (
    <nav className="relative z-0 inline-flex rounded-md -space-x-px">
      {currentPage !== 1 && (
        // prev link won't be display if current is the first page
        <Link
          className="relative inline-flex items-center px-2 py-2 rounded-l-md bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          to={getUrlForPage(currentPage - 1)}
        >
          <ChevronLeftIcon className="text-gray-500 h-5 w-5" />
        </Link>
      )}
      {itemList.map((item, i) => {
        // display the ellipsis
        if (item === startEllipsis || item === endEllipsis) {
          return (
            <span
              className="relative inline-flex items-center px-4 py-2 bg-gray-50 text-sm font-normal text-gray-700"
              key={`page_${i}`}
            >
              {item}
            </span>
          )
        }

        // displays the looped page
        return (
          <Link
            className={c(
              'hidden md:inline-flex relative items-center px-4 py-2 text-gray-700 text-sm border-r border-gray-100',
              // adds styles for currentPage
              item === currentPage
                ? 'bg-gray-100 hover:bg-gray-200 font-bold'
                : 'bg-gray-100 text-sm font-normal hover:bg-white bg-opacity-50 text-gray-700',
              // adds rounded border on the first item if current page is the first one
              // this needs to be applied because we removed the previous icon
              item === currentPage && item === 1 && 'rounded-l-md',
              // adds rounded border on the last item if current page is the last one
              // this needs to be applied because we removed the next icon
              currentPage === totalPages && item === totalPages && 'rounded-r-md'
            )}
            key={`page_${i}`}
            to={getUrlForPage(item)}
          >
            {item}
          </Link>
        )
      })}
      {currentPage !== totalPages && (
        // next link won't be display if current is the last page
        <Link
          className="relative inline-flex items-center px-2 py-2 rounded-r-md bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          to={getUrlForPage(Number(currentPage) + 1)}
        >
          <ChevronRightIcon className="text-gray-500 h-5 w-5" />
        </Link>
      )}
    </nav>
  )
}

export default Pager
