import { Photograph } from './Photograph'
import { User } from './User'
import { Activity } from './Activity'
import { QuantityDiscount } from './QuantityDiscount'
import { isNil } from 'lodash'
import { Event } from './Event'
import AlbumInterface from 'services/Interfaces/Album/Album.interface'
import LocationInterface from 'services/Interfaces/Location/Location.interface'

export default class Album {
  static init(data?: AlbumInterface): Album {
    const newAlbum = new Album()
    if (isNil(data)) {
      return newAlbum
    }
    newAlbum.id = data.id
    newAlbum.description = data.description
    newAlbum.takenDate = data.takenDate
    newAlbum.photographs = data.photographs
    newAlbum.coverPhotographSrc = data.coverPhotographSrc
    newAlbum.owner = data.owner
    newAlbum.ownerId = data.ownerId
    newAlbum.location = data.location
    newAlbum.locationId = data.location.id
    newAlbum.activity = data.activity
    newAlbum.activityId = data.activity.id
    newAlbum.isSuspended = data.isSuspended
    newAlbum.isFree = data.isFree
    newAlbum.createdAt = data.createdAt
    newAlbum.updatedAt = data.updatedAt
    newAlbum.deletedAt = data.deletedAt
    newAlbum.starsAverage = data.starsAverage

    if (!isNil(data.currency)) {
      newAlbum.currency = data.currency
    }
    if (!isNil(data.defaultImagePrice)) {
      newAlbum.defaultImagePrice = data.defaultImagePrice
    }
    if (!isNil(data.defaultPackagePrice)) {
      newAlbum.defaultPackagePrice = data.defaultPackagePrice
    }
    if (!isNil(data.burstPrice)) {
      newAlbum.burstPrice = data.burstPrice
    }
    if (!isNil(data.event)) {
      newAlbum.event = Event.init(data.event)
      newAlbum.eventId = data.event.id
    }
    if (!isNil(data.quantityDiscount)) {
      newAlbum.quantityDiscount = QuantityDiscount.init(data.quantityDiscount)
      newAlbum.quantityDiscountId = data.quantityDiscountId
    }

    return newAlbum
  }

  id: string

  description: string

  takenDate: Date

  currency?: string | null

  commission?: number | null

  defaultImagePrice?: number | null

  defaultPackagePrice?: number | null

  burstPrice?: number | null

  quantityDiscountId?: string | null

  quantityDiscount?: QuantityDiscount | null

  photographs: Photograph[]

  coverPhotographSrc: string

  owner: User

  ownerId: string

  location: LocationInterface

  locationId: string

  activity: Activity

  activityId: string

  isSuspended: boolean

  isFree: boolean

  event?: Event | null

  eventId?: string | null

  createdAt: Date

  updatedAt: Date

  deletedAt: Date

  starsAverage: number

  userLikedAlbum: boolean
}
