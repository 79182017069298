import { observer } from 'mobx-react'
import storesContext from 'providers/storesContext'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import UploadCoverPhotographStore, { ProgressInfo } from './UploadCoverPhotographStore'
import { isNil } from 'lodash'
import { ImageUploader } from './components/ImageUploader'
import ImageUploaded from './components/ImageUploaded'
import { Event } from 'models/Event'
import Album from 'models/Album'
import { UploadedPhotographsAssets } from 'services/PhotographService'

type UploadCoverPhotographProps = {
  event?: Event
  album?: Album
  isEventBanner?: boolean
  isEventCover?: boolean
  resetImage?: boolean
}

const UploadCoverPhotograph = ({
  event,
  album,
  isEventBanner = false,
  isEventCover = false,
  resetImage = false,
}: UploadCoverPhotographProps) => {
  const { authStore } = useContext(storesContext)!
  const [store] = useState(() => new UploadCoverPhotographStore(authStore))

  useEffect(() => {
    if (resetImage) {
      store.changeCoverPhotographUrl(null)
      store.changeImageSubmitted(true)
    }
  }, [resetImage])

  const auxSetProgressInfo = useCallback((pginfo: ProgressInfo) => {
    store.changeProgressInfo(pginfo)
  }, [])

  const onSubmitImage = (image: File) => {
    store.changeImageSubmitted(true)
    store.changeImage(image)
    const progressInfo = {
      percentage: 0,
      fileName: image.name,
      requestSent: false,
      error: false,
      size: image.size,
      type: image.type,
    }
    auxSetProgressInfo(progressInfo)
  }

  useEffect(() => {
    if (!isNil(event) && isEventCover && !isNil(event.coverPhotographSrc)) {
      store.changeCoverPhotographUrl(event.coverPhotographSrc)
    } else if (!isNil(event) && isEventBanner && !isNil(event.customBannerSrc)) {
      store.changeCoverPhotographUrl(event.customBannerSrc)
    } else if (!isNil(album) && !isNil(album.coverPhotographSrc)) {
      store.changeCoverPhotographUrl(album.coverPhotographSrc)
    }
  }, [])

  useEffect(() => {
    if (store.imageSubmitted && !isNil(store.image)) {
      if (!isNil(event) && isEventCover) {
        store.uploadCoverPhotograph(event.id, UploadedPhotographsAssets.EVENT_COVER)
      } else if (!isNil(event) && isEventBanner) {
        store.uploadCoverPhotograph(event.id, UploadedPhotographsAssets.EVENT_BANNER)
      } else if (!isNil(album)) {
        store.uploadCoverPhotograph(album.id, UploadedPhotographsAssets.ALBUM_COVER)
      }
    } else if (store.imageSubmitted && isNil(store.image)) {
      store.changeImageSubmitted(false)
    }
  }, [auxSetProgressInfo, store.image])

  let imageDisplayView
  !store.coverPhotographUrl
    ? (imageDisplayView = <ImageUploader onUpload={onSubmitImage} />)
    : (imageDisplayView = <ImageUploaded onUpload={onSubmitImage} store={store} />)

  return <>{imageDisplayView}</>
}

export default observer(UploadCoverPhotograph)
