import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'

const getEventValidationSchema = () => {
  const { t } = useTranslation()

  return yup.object({
    name: yup.string().required(t('Name is required') as string),
    description: yup.string().required(t('Description is required') as string),
    landingPath: yup.string().required(t('LandingPath is required') as string),
    activityId: yup.string().uuid().nullable().optional(),
    locationId: yup
      .string()
      .uuid()
      .required(t('Location is required') as string),
    participants: yup.number().optional(),
    customBannerSrc: yup.string().nullable().optional(),
    date: yup.date().required(t('Date is required') as string),
    isFree: yup.boolean().required(t('IsFree is required') as string),
    accreditationsEnabled: yup.boolean().required(t('AccreditationsEnabled is required') as string),
    displayInFeed: yup.boolean().required(t('DisplayInFeed is required') as string),
    faceRecognitionFilter: yup.boolean().required(t('FaceRecognitionFilter is required') as string),
    textFilter: yup.boolean().required(t('TextFilter is required') as string),
    albumsView: yup.boolean().required(t('AlbumsView is required') as string),
    filtersView: yup.boolean().required(t('FiltersView is required') as string),
    currency: yup
      .string()
      .test('currency-required-if-not-free', t('Currency is required') as string, function (value) {
        const { isFree } = this.parent
        if (!isFree) {
          return !!value
        }
        return true
      }),
    commission: yup
      .string()
      .test(
        'commission-required-if-not-free',
        t('Commission is required') as string,
        function (value) {
          const { isFree } = this.parent
          if (!isFree) {
            return !!value
          }
          return true
        }
      ),
    defaultImagePrice: yup
      .number()
      .nullable()
      .test({
        name: 'positive-if-not-nil',
        message: t('Default image price must be positive') as string,
        test: function (value) {
          return isNil(value) || value > 0
        },
      }),
    defaultPackagePrice: yup
      .number()
      .nullable()
      .test({
        name: 'price-comparison',
        message: t('Default package price must be greater than default image price') as string,
        test: function (defaultPackagePrice, { parent }) {
          return isNil(defaultPackagePrice) || defaultPackagePrice > parent.defaultImagePrice
        },
      }),
    burstPrice: yup
      .number()
      .nullable()
      .test({
        name: 'price-comparison',
        message: t('Burst price must be greater than default image price') as string,
        test: function (burstPrice, { parent }) {
          return isNil(burstPrice) || burstPrice > parent.defaultImagePrice
        },
      }),
    quantityDiscountId: yup.string().uuid().nullable(),
    password: yup
      .string()
      .optional()
      .nullable()
      .test('no-spaces', t('Password cannot contain spaces') as string, (value) => {
        if (!value) return true
        return !value.includes(' ')
      })
      .test(
        'required-if-private',
        t('Password is required when event is private') as string,
        function (value) {
          const { isPrivate } = this.parent
          if (isPrivate) {
            return !!value
          }
          return true
        }
      ),
  })
}

export default getEventValidationSchema
