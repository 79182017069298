import {
  CodeDiscountInterface,
  PurchaseCodeDiscountValidity,
} from 'services/Interfaces/Discount/CodeDiscount.interface'
import { CodeDiscountApplication } from 'services/Interfaces/Discount/CodeDiscount.interface'
import { CodeDiscountUsability } from 'services/Interfaces/Discount/CodeDiscount.interface'
import { BaseDiscount } from './BaseDiscount'

export enum CodeDiscountUnit {
  PERCENTAGE = 'PERCENTAGE',
  CURRENCY = 'CURRENCY',
}

export class CodeDiscount extends BaseDiscount {
  code: string

  amount: number

  unit: CodeDiscountUnit

  description: string

  usability: CodeDiscountUsability

  ownerId: string

  validity: PurchaseCodeDiscountValidity

  application: CodeDiscountApplication

  static init(data: CodeDiscountInterface): CodeDiscount {
    const discount = new CodeDiscount()
    discount.id = data.id
    discount.code = data.code
    discount.amount = data.amount
    discount.description = data.description
    discount.expirationDate = data.expirationDate
    discount.unit = data.unit
    discount.usability = data.usability
    discount.application = data.application
    discount.validity = data.validity
    discount.ownerId = data.ownerId

    return discount
  }
}
