import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import { UserPlusIcon } from '@heroicons/react/20/solid'
import SearchResultListNoResults from '../../../components/SearchResultListNoResults/SearchResultListNoResults'
import { User } from '../../../models/User'
import Spinner from '../../../components/UI/Spinner/Spinner'
import UserRow from './UserRow'
import TableHeader from 'services/Interfaces/Utility/TableHeader.interface'

type UsersListProps = {
  users: User[]
  isLoading: boolean
  handleClickEdit: (user: User) => void
  handleClickSuspend: (user: User) => void
  handleClickActivate: (user: User) => void
  handleClickDelete: (user: User) => void
  handleAddUser: () => void
}

const TABLE_HEADERS: TableHeader[] = [
  { key: 'name', label: 'Name', className: 'font-medium' },
  { key: 'email', label: 'Email' },
  { key: 'alias', label: 'Alias' },
  { key: 'country', label: 'Country' },
  { key: 'phone', label: 'Phone' },
  { key: 'instagram', label: 'Instagram' },
  { key: 'status', label: 'Status', className: 'font-medium' },
  { key: 'roles', label: 'Roles', className: 'font-medium' },
]

const UsersList = ({
  users = [],
  isLoading = false,
  handleClickEdit,
  handleClickSuspend,
  handleClickActivate,
  handleClickDelete,
  handleAddUser,
}: UsersListProps) => {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  if (users.length === 0) {
    return (
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center my-10">
          <SearchResultListNoResults />
          <div className="bg-teal-100 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md w-1/2 flex justify-center mt-8">
            <div className="flex">
              <span className="font-bold mr-4">{t('Add User')}</span>
              <UserPlusIcon
                className="text-primary-light h-6 w-6 cursor-pointer transition-colors hover:text-primary-dark"
                onClick={handleAddUser}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table className="max-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr className="font-bold">
            {TABLE_HEADERS.map(({ key, label, className }) => (
              <th
                key={key}
                scope="col"
                className={`px-6 py-3 text-left text-xs text-gray-500 uppercase ${className || ''}`}
              >
                {t(label)}
              </th>
            ))}
            <th scope="col" className="relative px-6 py-3 flex justify-end">
              <UserPlusIcon
                className="text-primary-light h-6 w-6 cursor-pointer transition-colors hover:text-primary-dark"
                onClick={handleAddUser}
              />
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {users.map((user, index) => (
            <UserRow
              key={user.id || index}
              user={user}
              handleClickDelete={handleClickDelete}
              handleClickEdit={handleClickEdit}
              handleClickSuspend={handleClickSuspend}
              handleClickActivate={handleClickActivate}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default withTranslation()(observer(UsersList))
