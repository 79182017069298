import { isNil } from 'lodash'
import { CodeDiscount } from './CodeDiscount'
import Album from './Album'
import UserInterface from 'services/Interfaces/User/User.interface'
import { RoleType } from 'services/Interfaces/User/UserRole.interface'

export class Role {
  constructor(type?: RoleType) {
    if (!isNil(type)) {
      this.type = type
    }
  }
  type: RoleType
}

export class User {
  id: string

  firstName: string

  lastName: string

  alias: string

  email: string

  password: string

  isSuspended?: boolean

  mercadoPagoVerified?: boolean

  personalCodeDiscount?: CodeDiscount

  albums?: Album[]

  clients?: User[]

  clientOf?: User

  dateRegisteredAsClient?: Date

  roles: Role[]

  createdAt!: Date

  isVerified: boolean

  instagramUsername?: string

  facebookUsername?: string

  phonePrefix?: string

  phoneNumber?: string

  profilePictureUrl?: string

  isEnabledToSell: boolean

  stripeAccountId?: string

  stripeOnboardingCompleted: boolean

  countryCode?: string

  get isPhotographer() {
    return !isNil(this.roles.find((role) => role.type === RoleType.PHOTOGRAPHER))
  }

  get isAthlete() {
    return !isNil(this.roles.find((role) => role.type === RoleType.ATHLETE))
  }

  get isAdmin() {
    return !isNil(this.roles.find((role) => role.type === RoleType.ADMIN))
  }

  static init(userData?: UserInterface): User {
    const user = new User()
    if (isNil(userData)) {
      return user
    }
    user.id = userData.id
    user.firstName = userData.firstName
    user.lastName = userData.lastName
    user.alias = userData.alias
    user.email = userData.email
    user.isSuspended = userData.isSuspended
    user.mercadoPagoVerified = userData.mercadoPagoVerified
    user.roles = !isNil(userData.roles) ? userData.roles.map((role) => new Role(role.type)) : []
    user.createdAt = userData.createdAt
    user.isVerified = userData.isVerified
    user.instagramUsername = userData.instagramUsername
    user.facebookUsername = userData.facebookUsername
    user.phonePrefix = userData.phonePrefix
    user.phoneNumber = userData.phoneNumber
    user.profilePictureUrl = userData.profilePictureUrl
    user.countryCode = userData.countryCode
    user.stripeAccountId = userData.stripeAccountId
    user.isEnabledToSell = userData.isEnabledToSell || false
    user.stripeOnboardingCompleted = userData.stripeOnboardingCompleted

    if (!isNil(userData.personalCodeDiscount)) {
      user.personalCodeDiscount = CodeDiscount.init(userData.personalCodeDiscount)
    }

    user.clients = !isNil(userData.clients)
      ? userData.clients.map((client) => User.init(client))
      : []
    user.clientOf = User.init(userData.clientOf)
    if (!isNil(user.clientOf)) {
      user.dateRegisteredAsClient = userData.dateRegisteredAsClient
    }

    return user
  }
}
