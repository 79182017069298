export enum RoleType {
  ADMIN = 'admin',
  ATHLETE = 'athlete',
  PHOTOGRAPHER = 'photographer',
}

export default interface RoleInterface {
  id?: number
  type: RoleType
}
