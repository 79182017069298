import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import StoresContext from '../../../../providers/storesContext'
import Modal, { ModalButtonsDisplay, ModalSize } from '../../../../components/UI/Modal/Modal'
import ServerError from '../../../../components/UI/ServerError'
import Spinner from '../../../../components/UI/Spinner/Spinner'
import { Event } from '../../../../models/Event'
import { isNil } from 'lodash'
import LocationService from 'services/LocationService'
import ActivityService from 'services/ActivityService'
import { Activity } from 'models/Activity'
import { Location } from 'models/Location'
import { CurrencyEnum } from 'models/CurrencyEnum'
import { Autocomplete, Checkbox, TextField } from '@mui/material'
import EventStore from 'containers/Events/EventsStore'
import UploadCoverPhotograph from 'components/UI/ImageUploader/UploadCoverPhotograph'
import { useFormik } from 'formik'
import getEventValidationSchema from './EventValidationSchema'
import moment from 'moment'
import CreateOrUpdateEventRequestInterface from 'services/Interfaces/Event/CreateEventRequest.interface'
import { TrashIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'

type EventFormModalType = {
  open: boolean
  handleClose: (event?: Event) => void
  eventToEdit?: Event | null
  eventStore: EventStore
}

const EventFormModal = ({
  open,
  handleClose,
  eventToEdit = null,
  eventStore,
}: EventFormModalType) => {
  const { authStore } = useContext(StoresContext)!
  const { t } = useTranslation()
  const locationService = new LocationService()
  const activityService = new ActivityService()
  const [locations, setLocations] = useState<Location[]>([])
  const [activities, setActivities] = useState<Activity[]>([])
  const [currencies, setCurrencies] = useState<CurrencyEnum[]>([])
  const [resetBanner, setResetBanner] = useState(false)
  const [showPasswordField, setShowPasswordField] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    loadLocations()
    loadActivities()
    loadCurrencies()
  }, [])

  //TODO: Move this to LocationStore
  const loadLocations = async () => {
    const locationsList = await locationService.fetchLocations(authStore.getAdminToken())
    setLocations(locationsList)
  }
  //TODO: Move this to ActivityStore
  const loadActivities = async () => {
    const activitiesList = await activityService.fetchActivities(authStore.getAdminToken())
    setActivities(activitiesList)
  }
  const loadCurrencies = async () => {
    const currenciesList = Object.values(CurrencyEnum)
    setCurrencies(currenciesList)
  }

  const initialValues: CreateOrUpdateEventRequestInterface = !isNil(eventToEdit)
    ? {
        id: eventToEdit.id || '',
        name: eventToEdit?.name || '',
        description: eventToEdit?.description || '',
        landingPath: eventToEdit?.landingPath || '',
        activityId: eventToEdit?.activity?.id || null,
        locationId: eventToEdit?.location?.id || '',
        date: eventToEdit?.date || new Date(new Date().setHours(0, 0, 0, 0)),
        currency: eventToEdit?.currency || '',
        commission: eventToEdit?.commission || 20,
        defaultImagePrice: eventToEdit?.defaultImagePrice || null,
        defaultPackagePrice: eventToEdit?.defaultPackagePrice || undefined,
        burstPrice: eventToEdit?.burstPrice || undefined,
        quantityDiscountId: eventToEdit?.quantityDiscountId || undefined,
        displayInFeed: eventToEdit?.displayInFeed || true,
        isFree: eventToEdit?.isFree || false,
        accreditationsEnabled: eventToEdit?.accreditationsEnabled || false,
        faceRecognitionFilter: eventToEdit?.faceRecognitionFilter || false,
        textFilter: eventToEdit?.textFilter || false,
        albumsView: eventToEdit?.albumsView || false,
        filtersView: eventToEdit?.filtersView || false,
        participants: eventToEdit?.participants || undefined,
        password: eventToEdit?.password || null,
      }
    : {
        name: '',
        description: '',
        landingPath: '',
        activityId: '',
        locationId: '',
        date: new Date(new Date().setHours(0, 0, 0, 0)),
        currency: undefined,
        commission: 20,
        defaultImagePrice: null,
        defaultPackagePrice: null,
        burstPrice: null,
        quantityDiscountId: undefined,
        displayInFeed: true,
        isFree: false,
        accreditationsEnabled: false,
        faceRecognitionFilter: true,
        textFilter: true,
        albumsView: false,
        filtersView: true,
        participants: undefined,
        password: null,
      }

  const formik = useFormik({
    initialValues,
    validationSchema: getEventValidationSchema(),
    onSubmit: (values) => {
      if (isNil(eventToEdit)) {
        if (typeof values.defaultPackagePrice === 'string') {
          values.defaultPackagePrice = undefined
        }
        if (typeof values.burstPrice === 'string') {
          values.burstPrice = undefined
        }
        eventStore.createEvent(values).then((newEvent) => {
          if (newEvent) {
            handleClose(newEvent)
          }
        })
      } else {
        eventStore.editEvent(values).then((updatedEvent) => {
          if (updatedEvent) {
            handleClose()
            eventStore.fetchEvents(1, 25)
          }
        })
      }
    },
  })

  const activityOptions = activities
    .map((activity) => {
      return { value: activity.id, label: t(activity.name).toUpperCase() }
    })
    .sort((a, b) => -b.label[0].localeCompare(a.label[0]))

  const locationOptions = locations
    .map((location) => {
      return { value: location.id, label: location.spotName.toUpperCase() }
    })
    .sort((a, b) => -b.label[0].localeCompare(a.label[0]))

  if (eventStore.isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <Modal
      okMessage={isNil(eventToEdit) ? t('Add') : t('Save')}
      loadingOk={eventStore.isLoading}
      onCancel={handleClose}
      onSuccess={formik.handleSubmit}
      opened={open}
      buttonsDisplay={ModalButtonsDisplay.ONLY_OK_BUTTON}
      modalSize={ModalSize.MEDIUM}
    >
      <div className="w-full space-y-6">
        <div className="border-b pb-4">
          <h2 className="text-xl text-gray-800 font-bold">
            {isNil(eventToEdit) ? t('Create Event') : t('Edit Event')}
          </h2>
          {eventStore.error && <ServerError message={t(eventStore.error?.message)} />}
        </div>

        {!isNil(eventToEdit) && (
          <div className="space-y-4">
            <h3 className="text-sm font-bold text-gray-500 uppercase">{t('Event Media')}</h3>
            <div className="flex flex-col md:flex-row gap-6">
              <div className="w-full md:w-2/3">
                <p className="text-gray-400 font-bold text-sm mb-1">{t('Cover photograph')}</p>
                <UploadCoverPhotograph event={eventToEdit} isEventCover={true} />
              </div>

              <div className="w-full md:w-1/3">
                <p className="text-gray-400 font-bold text-sm mb-1">{t('Custom banner')}</p>
                <UploadCoverPhotograph
                  event={eventToEdit}
                  isEventBanner={true}
                  resetImage={resetBanner}
                />
                {!isNil(eventToEdit.customBannerSrc) && (
                  <div className="flex items-center gap-1 mt-1">
                    <TrashIcon
                      className="text-red-600 h-5 w-5 cursor-pointer transition-colors"
                      onClick={() => {
                        formik.setFieldValue('customBannerSrc', null)
                        setResetBanner(true)
                      }}
                      title={t('Remove banner').toString()}
                    />
                    <span
                      className="text-red-600 underline text-base cursor-pointer"
                      onClick={() => {
                        formik.setFieldValue('customBannerSrc', null)
                        setResetBanner(true)
                      }}
                    >
                      {t('Remove banner')}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="space-y-4">
          <h3 className="text-sm font-bold text-gray-500 uppercase">{t('Basic Information')}</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <TextField
              fullWidth
              variant="outlined"
              id="name"
              name="name"
              label={t('Name')}
              required
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              fullWidth
              variant="outlined"
              id="landingPath"
              name="landingPath"
              label={t('Landing path')}
              required
              value={formik.values.landingPath}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.landingPath && Boolean(formik.errors.landingPath)}
              helperText={formik.touched.landingPath && formik.errors.landingPath}
            />
            <TextField
              fullWidth
              variant="outlined"
              id="description"
              name="description"
              label={t('Description')}
              required
              multiline
              rows={3}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-sm font-bold text-gray-500 uppercase">{t('Event Details')}</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <TextField
              fullWidth
              id="date"
              name="date"
              label={t('Date')}
              required
              InputLabelProps={{ shrink: true }}
              type="Date"
              value={moment.utc(formik.values.date).format('YYYY-MM-DD')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={
                formik.touched.date
                  ? typeof formik.errors.date === 'string'
                    ? formik.errors.date
                    : ''
                  : ''
              }
            />
            <TextField
              fullWidth
              variant="outlined"
              id="participants"
              name="participants"
              label={t('Participants')}
              type="number"
              value={formik.values.participants}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.participants && Boolean(formik.errors.participants)}
              helperText={formik.touched.participants && formik.errors.participants}
            />
            <Autocomplete
              options={activityOptions}
              value={
                activityOptions.find((activity) => activity.value === formik.values.activityId) ||
                null
              }
              getOptionLabel={(option) => option.label}
              onChange={(activity, newValue) => {
                formik.setFieldValue('activityId', newValue ? newValue.value : null)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  name="activityId"
                  id="activityId"
                  label={t('Sport')}
                  error={formik.touched.activityId && Boolean(formik.errors.activityId)}
                  helperText={formik.touched.activityId && formik.errors.activityId}
                />
              )}
            />
            <Autocomplete
              options={locationOptions}
              value={
                locationOptions.find((location) => location.value === formik.values.locationId) ||
                null
              }
              getOptionLabel={(option) => option.label}
              onChange={(location, newValue) => {
                formik.setFieldValue('locationId', newValue ? newValue.value : '')
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  name="locationId"
                  id="locationId"
                  label={t('Location')}
                  required
                  error={formik.touched.locationId && Boolean(formik.errors.locationId)}
                  helperText={formik.touched.locationId && formik.errors.locationId}
                />
              )}
            />
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-sm font-bold text-gray-500 uppercase">{t('Event Settings')}</h3>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div className="flex items-center gap-2">
              <Checkbox
                onChange={() => formik.setFieldValue('isFree', !formik.values.isFree)}
                checked={formik.values.isFree}
              />
              <span className="text-sm text-gray-600">{t('Free Event')}</span>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                onChange={() =>
                  formik.setFieldValue(
                    'accreditationsEnabled',
                    !formik.values.accreditationsEnabled
                  )
                }
                checked={formik.values.accreditationsEnabled}
              />
              <span className="text-sm text-gray-600">{t('Accreditations')}</span>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                onChange={() => formik.setFieldValue('displayInFeed', !formik.values.displayInFeed)}
                checked={formik.values.displayInFeed}
              />
              <span className="text-sm text-gray-600">{t('Display in feed')}</span>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                onChange={() => setShowPasswordField(!showPasswordField)}
                checked={showPasswordField || !isNil(formik.values.password)}
              />
              <span className="text-sm text-gray-600">{t('Private Event')}</span>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                onChange={() =>
                  formik.setFieldValue(
                    'faceRecognitionFilter',
                    !formik.values.faceRecognitionFilter
                  )
                }
                checked={formik.values.faceRecognitionFilter}
              />
              <span className="text-sm text-gray-600">{t('Face Recognition')}</span>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                onChange={() => formik.setFieldValue('textFilter', !formik.values.textFilter)}
                checked={formik.values.textFilter}
              />
              <span className="text-sm text-gray-600">{t('Text Filter')}</span>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                onChange={() => formik.setFieldValue('albumsView', !formik.values.albumsView)}
                checked={formik.values.albumsView}
              />
              <span className="text-sm text-gray-600">{t('Albums View')}</span>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                onChange={() => formik.setFieldValue('filtersView', !formik.values.filtersView)}
                checked={formik.values.filtersView}
              />
              <span className="text-sm text-gray-600">{t('Filters View')}</span>
            </div>
          </div>
        </div>

        {!formik.values.isFree && (
          <div className="space-y-4">
            <h3 className="text-sm font-bold text-gray-500 uppercase">{t('Pricing')}</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="space-y-4">
                <Autocomplete
                  options={currencies}
                  value={currencies.find((currency) => currency === formik.values.currency) || null}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('currency', newValue ? newValue : undefined)
                  }}
                  getOptionLabel={(option) => (option ? option.toUpperCase() : '')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="currency"
                      id="currency"
                      label={t('Currency')}
                      required
                      error={formik.touched.currency && Boolean(formik.errors.currency)}
                      helperText={formik.touched.currency && formik.errors.currency}
                    />
                  )}
                />
                <TextField
                  id="commission"
                  name="commission"
                  label={t('Commission')}
                  type="number"
                  required
                  fullWidth
                  value={formik.values.commission}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.commission && Boolean(formik.errors.commission)}
                  helperText={formik.touched.commission && formik.errors.commission}
                />
              </div>

              <div className="space-y-4">
                <TextField
                  id="defaultImagePrice"
                  name="defaultImagePrice"
                  label={t('Default Image Price')}
                  fullWidth
                  type="number"
                  value={formik.values.defaultImagePrice}
                  onChange={(e) => {
                    const value = e.target.value
                    formik.setFieldValue('defaultImagePrice', value === '' ? null : Number(value))
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.defaultImagePrice && Boolean(formik.errors.defaultImagePrice)
                  }
                  helperText={formik.touched.defaultImagePrice && formik.errors.defaultImagePrice}
                />
                <TextField
                  id="defaultPackagePrice"
                  name="defaultPackagePrice"
                  label={t('Default Package Price')}
                  type="number"
                  fullWidth
                  value={formik.values.defaultPackagePrice}
                  onChange={(e) => {
                    const value = e.target.value
                    formik.setFieldValue('defaultPackagePrice', value === '' ? null : Number(value))
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.defaultPackagePrice && Boolean(formik.errors.defaultPackagePrice)
                  }
                  helperText={
                    formik.touched.defaultPackagePrice && formik.errors.defaultPackagePrice
                  }
                />
              </div>

              <div className="space-y-4">
                <TextField
                  id="burstPrice"
                  name="burstPrice"
                  label={t('Burst Price')}
                  type="number"
                  fullWidth
                  value={formik.values.burstPrice}
                  onChange={(e) => {
                    const value = e.target.value
                    formik.setFieldValue('burstPrice', value === '' ? null : Number(value))
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.burstPrice && Boolean(formik.errors.burstPrice)}
                  helperText={formik.touched.burstPrice && formik.errors.burstPrice}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  id="quantityDiscountId"
                  name="quantityDiscountId"
                  label={t('Quantity Discount ID')}
                  value={formik.values.quantityDiscountId}
                  onChange={(e) => {
                    const value = e.target.value
                    formik.setFieldValue('quantityDiscountId', value === '' ? null : value)
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.quantityDiscountId && Boolean(formik.errors.quantityDiscountId)
                  }
                  helperText={formik.touched.quantityDiscountId && formik.errors.quantityDiscountId}
                />
              </div>
            </div>
          </div>
        )}

        {(showPasswordField || !isNil(formik.values.password)) && (
          <div className="space-y-4">
            <h3 className="text-sm font-bold text-gray-500 uppercase">{t('Access Control')}</h3>
            <div className="w-full md:w-1/3">
              <TextField
                fullWidth
                variant="outlined"
                id="password"
                name="password"
                label={t('Password')}
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password || ''}
                onChange={(e) => {
                  const value = e.target.value.trim()
                  formik.setFieldValue('password', value === '' ? null : value)
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <div
                      className="cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                      title={
                        showPassword ? t('Hide password').toString() : t('Show password').toString()
                      }
                    >
                      {showPassword ? (
                        <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                      ) : (
                        <EyeIcon className="h-5 w-5 text-gray-500" />
                      )}
                    </div>
                  ),
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default withTranslation('common')(observer(EventFormModal))
