import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from 'stores/AuthStore'
import UserService from '../../services/UserService'
import { User } from '../../models/User'

class UserStore {
  private readonly userService: UserService
  public users: User[]
  public referentUsers: User[]
  public refereedUsers: User[]
  public paginator: any
  public isLoading: boolean
  public error: any
  constructor(private readonly authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.userService = new UserService()
    this.authStore = authStore
    this.paginator = 1
  }

  reset() {
    this.users = []
    this.referentUsers = []
    this.refereedUsers = []
    this.paginator = null
    this.isLoading = false
    this.error = false
    this.paginator = 1
  }

  clearError() {
    this.error = null
  }

  setError(val: string) {
    this.error = val
  }

  async fetchUsers(page = 1, take: number, searchInput?: string) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const response = await this.userService.fetchUsers(
        this.authStore.getToken(),
        page,
        take,
        searchInput
      )
      runInAction(() => {
        this.isLoading = false
        this.users = response.users
        this.paginator = response.paginator
        this.error = null
      })
      return response
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async fetchReferentUsers() {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const response = await this.userService.fetchReferentUsers(this.authStore.getToken())
      runInAction(() => {
        this.isLoading = false
        this.referentUsers = response
        this.error = null
      })
      return response
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async fetchRefereedUsers(page: number, take: number, userId?: string) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const response = await this.userService.fetchRefereedUsers(
        this.authStore.getToken(),
        page,
        take,
        userId
      )
      runInAction(() => {
        this.isLoading = false
        this.refereedUsers = response.users
        this.paginator = response.paginator
        this.error = null
      })
      return response
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async suspendUser(user: User) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const userSuspended = await this.userService.suspendUser(this.authStore.getToken(), user.id)
      runInAction(() => {
        this.isLoading = false
      })
      return userSuspended
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async activateUser(user: User) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const userActivate = await this.userService.activateUser(this.authStore.getToken(), user.id)
      runInAction(() => {
        this.isLoading = false
      })
      return userActivate
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async deleteUser(userToDelete: User) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      await this.userService.deleteUser(this.authStore.getToken(), userToDelete.id)
      runInAction(() => {
        this.isLoading = false
        // this.users.remove(userToDelete)
      })
    } catch (e: any) {
      this.error = e
      this.isLoading = false
      return e?.message
    }
  }

  async addRefereedUser(referentAlias: string, referredAlias: string) {
    runInAction(() => {
      this.isLoading = true
    })

    try {
      const response = await this.userService.addRefereedUser(this.authStore.getAdminToken(), {
        referentAlias,
        referredAlias,
      })

      runInAction(() => {
        this.isLoading = false
        this.error = null
      })
      return response
    } catch (e) {
      runInAction(() => {
        this.error = e
        this.isLoading = false
      })
    }
  }
}

export default UserStore
