import React, { useState, useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import LoginStore from '../LoginStore'
import StoresContext from '../../../providers/storesContext'
import 'moment/locale/es'
import LoginRegisterForm from '../components/LoginRegisterForm'
import { DASHBOARD } from '../../../routing/Paths'
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../../services/axios'
import ServerError from '../../../components/UI/ServerError'
import Button from '../../../components/UI/Button'
import Spinner from '../../../components/UI/Spinner/Spinner'
import { RoleType } from 'services/Interfaces/User/UserRole.interface'

const Login = () => {
  const navigate = useNavigate()
  const { authStore } = useContext(StoresContext)!
  const { t } = useTranslation('common')
  const [loginRegisterStore] = useState(() => new LoginStore(authStore))
  const { isLoading } = loginRegisterStore

  const handleLoginSubmit = async () => {
    await loginRegisterStore.login()
    if (authStore.getLoggedUser()?.roles.some((role) => role.type === RoleType.ADMIN)) {
      navigate(DASHBOARD)
    }
  }

  if (isLoading) {
    return (
      <div className="w-full flex flex-col justify-center">
        <div className="mt-14">
          <Spinner size={10} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <div className="w-1/3 ">
      {authStore.error && <ServerError message={t(`${authStore.error}`).toString()} />}
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleLoginSubmit()
        }}
      >
        <LoginRegisterForm loginRegisterStore={loginRegisterStore} />
        <div className="flex justify-end mt-2">
          <Button gold>{t('Login')}</Button>
        </div>
      </form>
    </div>
  )
}

export default withErrorHandler(withTranslation('common')(observer(Login)), axios)
