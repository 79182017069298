import { adminApiInstance } from './axios'
import { AxiosResponse } from 'axios'
import * as paths from '../services/Paths'
import { parseAxiosErrorResponse } from '../utility'
import LoginRequest from './Interfaces/Auth/LoginRequest'
import LoginResponse from './Interfaces/Auth/LoginResponse'

class AuthService {
  public async adminLogin(email: string, password: string): Promise<LoginResponse> {
    const loginData: LoginRequest = {
      email: email,
      password: password,
    }

    return adminApiInstance
      .post(paths.LOGIN, loginData)
      .then((response: AxiosResponse) => {
        return response.data as LoginResponse
      })
      .catch(parseAxiosErrorResponse)
  }
}

export default AuthService
