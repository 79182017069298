import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'

const getAlbumValidationSchema = () => {
  const { t } = useTranslation()

  return yup.object({
    description: yup.string().required(t('Description is required') as string),
    eventId: yup.string().uuid().nullable(),
    activityId: yup
      .string()
      .uuid()
      .required(t('Activity is required') as string),
    locationId: yup
      .string()
      .uuid()
      .required(t('Location is required') as string),
    takenDate: yup.date().required(t('Date is required') as string),
    isFree: yup.boolean().required(t('IsFree is required') as string),
    currency: yup
      .string()
      .test('currency-required-if-not-free', t('Currency is required') as string, function (value) {
        const { isFree } = this.parent
        if (!isFree) {
          return !!value
        }
        return true
      }),
    defaultImagePrice: yup
      .number()
      .test(
        'imagePrice-required-if-not-free',
        t('DefaultImagePrice is required') as string,
        function (value) {
          const { isFree } = this.parent
          if (!isFree) {
            return !!value
          }
          return true
        }
      ),
    defaultPackagePrice: yup
      .number()
      .nullable()
      .test({
        name: 'price-comparison',
        message: t('Default package price must be greater than default image price') as string,
        test: function (defaultPackagePrice, { parent }) {
          return isNil(defaultPackagePrice) || defaultPackagePrice > parent.defaultImagePrice
        },
      }),
    burstPrice: yup
      .number()
      .nullable()
      .test({
        name: 'price-comparison',
        message: t('Burst price must be greater than default image price') as string,
        test: function (burstPrice, { parent }) {
          return isNil(burstPrice) || burstPrice > parent.defaultImagePrice
        },
      }),
    quantityDiscountId: yup.string().uuid().nullable(),
  })
}

export default getAlbumValidationSchema
